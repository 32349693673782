<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Recall Leaves</h1>
                </div>
              </div>

              <div class="pa-5 d-flex">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-row no-gutters>
                      <v-col cols="6 d-flex mt-auto mb-auto">
                        <v-select v-model="action" class="mr-5 action-select" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0" label="Actions" return-object outlined dense />
                      </v-col>
                      <v-col cols="6 d-flex mt-auto mb-auto">
                        <div class="submit-button-container">
                          <Button :label="'Submit'"
                                  :btnType="'Submit'"
                                  :disabled="action === '' || loading"
                                  @onClick="approveBulkSelection"
                                  :isLoading="loading"
                                  class="" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-text-field v-model="searchPersonnel"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
              <v-data-table loading-text="Loading data. Please wait...."
                            :loading="leaves.length == 0"
                            :headers='headers'
                            :search="searchPersonnel"
                            :items="leaves"
                            show-select
                            :single-select="false"
                            v-model="selected"
                            item-key="employeeId">
                <template v-slot:item.request.dateAdded="{ item }">
                  {{item.request.dateAdded | formatDate}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfirmDialog(item)" class="mr-2" />
                    <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2" />
                     <Button :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
                  </div>
                </template>

              </v-data-table>

              <!-- reject dialog  -->
              <v-dialog v-model="rejectDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
                  <v-divider class="mx-4 mb-5"></v-divider>
                  <v-card-text class="mt-5">
                    <v-form ref="commentForm" v-model="valid">
                      <TextField v-model="formData.comments" :label="'Comment'" />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button :label="'Reject'"
                            :btnType="'Submit'"
                            @onClick="reject"
                            :disabled="rejectLoading"
                            :isLoading="rejectLoading"
                            class="mr-4" />
                    <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-container>
          </div>
        </div>
         <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment" :mType="'forward'"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
        <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="approveLoading"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="view()"/>
      </v-app>
</template>
<script>
import moment from 'moment'
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
// import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { requestService } from '@/services'
// import { FETCH_LEAVE } from '@/store/action-type'
export default {
  components: {
    Button,
    TextField,
    PersonnelSearch,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      loading: false,
      searchPersonnel: '',
      isLoading: false,
      rejectDialog: false,
      rejectLoading: false,
      approveLoading: false,
      dialog: false,
      action: '',
      formData: {
        id: '',
        startDate: '',
        days: '',
        resumptionDate: '',
        comments: '',
        leaveType: null,
        EmployeeId: ''
      },
      leave: null,
      valid: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      personnelSearch: false,     
      DelegateName: null,
      DelegateEmail: null,
      forwarding: false,
      comment: '',
      alertType: '',
      leaves: [],
      selected: [],
      leaveTypes: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      headers: [
        {
          text: 'Personnel Name',
          value: 'employeeName'
        },
        {
          text: 'Leave Type',
          value: 'leaveType'
        },
        {
          text: 'Days Recalled',
          value: 'leaveDays',
          align: 'center'
        },
        {
          text: 'Comment',
          value: 'comment'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    startDateSelected () {
      const data = { EmployeeId: this.formData.EmployeeId, startDate: this.formData.startDate, days: this.formData.days }

      requestService.getResumptionDate(data).then(res => {
        this.formData.resumptionDate = moment(res.data).format('YYYY-MM-DD')
      }).catch(() => {
        this.showAlertMessage('Failed to load Resumption Date', 'error')
      })
    },
    Add () {
      this.$router.push('/leave/add')
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    reject () {
      if (this.selected.length > 0) {
        return this.approveBulkSelection()
      }

      this.rejectLoading = true
      const payload = []
      payload.push({
        id: this.item.employeeId,
        status: 1,
        comments: this.item.comments
      })

      requestService.approveRecall(payload).then(result => {
        this.showAlertMessage('Leave recall successfully approved', 'success')
        this.leaves = []
      }).catch(() => {
        this.showAlertMessage('Unable to approve recall, try again later', 'error')
      }).finally(() => {
        this.rejectLoading = false
        this.rejectDialog = false
      })

      requestService.getAllPendingLeave().then(result => {
        this.leaves = result.data
      })
    },
    editModal (item) {
      this.$refs.editModal.openModal()
      this.formData.startDate = moment(item.request.startDate).format('YYYY-MM-DD')
      this.formData.resumptionDate = moment(item.request.resumptionDate).format('YYYY-MM-DD')
      this.formData.days = item.request.leaveDays
      const leaveType = this.leaveTypes.filter(leave => leave.id === item.employeeLeaveTypeId)
      this.formData.leaveType = leaveType[0]
      this.formData.id = item.requestId
      this.formData.EmployeeId = item.request.employeeId
      this.formData.comments = item.request.comments

      this.leave = item
    },
    openConfirmDialog (item) {
      this.dialog = true
      this.item = item
    },
    approveBulkSelection () {
      this.loading = true
      if (this.action.id === 2 && this.formData.comments === '') {
        this.rejectDialog = true
        this.bulk = true
        return
      }

      let bulkItems = []

      this.selected.forEach(element => {
        bulkItems.push({ 'id': element.employeeId, Status: this.action.id, Comments: this.action.id === 1 ? '' : this.formData.comments, Level: 0 })
      })

      this.rejectDialog = false

      requestService.approveRecall(bulkItems).then(result => {
        this.showAlertMessage(`${this.action.name}  Requests successful`, 'success')
        const myArrayFiltered = this.leaves.filter((el) => {
          return bulkItems.some((f) => {
            return f.id !== el.employeeId
          })
        })

        this.leaves = myArrayFiltered
      }).catch(() => {
        this.showAlertMessage(`${this.action.name}  Requests failed`, 'error')
      }).finally(() => {
        bulkItems = []
        this.loading = false
        this.formData.comments = ''
        this.selected = []
      })
    },
    view () {
      this.approveLoading = true
      const payload = []
      payload.push({
        id: this.item.employeeId,
        status: 1,
        comments: this.item.comments
      })

      requestService.approveRecall(payload).then(result => {
        this.showAlertMessage('Leave recall successfully approved', 'success')
        this.leaves = this.leaves.filter((lv) => lv.employeeId !== this.item.employeeId)
      }).catch(() => {
        this.showAlertMessage('Unable to approve recall, try again later', 'error')
      }).finally(() => {
        this.approveLoading = false
        this.dialog = false
        this.item = []
      })

      requestService.getAllPendingLeave().then(result => {
        this.leaves = result.data
      })
    },
    closeLeaveModal () {
      this.$refs.leaveViewModal.closeModal()
    },
    resetForm () {
      this.formData.id = ''
      this.formData.startDate = ''
      this.formData.days = ''
      this.formData.resumptionDate = ''
      this.formData.comments = ''
      this.formData.leaveType = null
    },
    update () {
      this.isLoading = true
      let data = {
        EmployeeId: this.formData.EmployeeId,
        RequestTypeId: 2,
        leaveTypeId: this.formData.leaveType.id,
        leaveDays: this.formData.days,
        StartDate: this.formData.startDate,
        ResumptionDate: this.formData.resumptionDate,
        comments: this.formData.comments,
        id: this.formData.id
      }
      requestService.updateLeave(data).then(result => {
        this.showAlertMessage('Leave Updated successful', 'success')
        this.$refs.editModal.closeModal()
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Leave Update failed', 'error')
      }).finally(() => {
        this.resetForm()
        this.isLoading = false
      })
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.closeModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.closeModal()
      }
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name      
    },
    getComment (comment) {
      this.comment = comment
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        itemId: this.item.employeeId,
        // "ItemId":428,//This should be the Employee Id        
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail,
        'Comment': this.comment
      }

      requestService.forwardLeaveRecal(data).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    }

  },
  mounted () {
    requestService.getAllPendingLeave().then(result => {
      this.leaves = result.data
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
.personal-name {
  text-align: left;
  font-weight: 600;
  font-size: 14px !important
}
.action-select {
 padding-top: 24px;
}
</style>
